<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-apps</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Dokumentation</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
        clearable
        id="search"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'DocumentationTools' }"> tools </v-tab>
          <v-tab :to="{ name: 'DocumentationGlobal' }"> global </v-tab>
          <v-tab :to="{ name: 'DocumentationComponents' }"> components </v-tab>
          <v-tab :to="{ name: 'DocumentationForms' }"> forms </v-tab>
          <v-tab :to="{ name: 'DocumentationNotes' }"> notes </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <router-view :search="search"></router-view>
  </v-container>
</template>
<script>
export default {
  name: 'Documentation',

  data: () => ({ search: '' }),
};
</script>